export default {

  Comparison: 'Karşılaştır',
  Analysis: 'Analiz',
  dashboard: 'Konsol',
  cube: 'Küp',
  reports: 'Raporlar',
  menu: 'Rapor Menü',
  dashlist: 'Tablo Listesi',
  cubes: 'Küpler',
  rawdata: 'Ham Veri',
  desk: 'Yardım Masası',
  forecast: 'Tahminler',
  livedata: 'Canlı Veri',
  settings: 'Ayarlar',
  notification: 'Bildirimler',
  permissions: 'Yetkilendirme',
  profile: 'Profil',
  googlemaps: 'Google Haritalar',
  Hourly: 'Saatlik',
  Daily: 'Günlük',
  Weekly: 'Haftalık',
  Monthly: 'Aylık',
  Yearly: 'Yıllık',
  stats: 'Genel İstatistik',
  Stringbox: 'Stringbox',
  İnverter: 'İnverter',
  String: 'String',
  Combinerbox: 'Combinerbox',
  Inverter: 'İnverter',
  Timeinverter: 'Timeinverter',
  Minute: 'Minute',
  santral: 'santral',
  Sensorbox: 'Sensörler',
  SantralDash: 'Dashboard',

  home: 'Anasayfa',
  Notifications: 'Bildirimler',
  Error: 'Hata',
  Warning: 'Uyarı',
  heatmap: 'Heatmap',
  Santral_Analysis: 'Santral Analiz',
  DateAnalysis: 'Tarih Analiz',

  cubePages: {
    cube: 'Küp',
    list: 'Liste',
    cubelist: 'Küp Listesi',
    pivot: 'Pivot Tablo',
    chart: 'Grafik',
  },

  rawPages: {
    order: 'Sipariş',
    orderProduct: 'Sipariş/Ürün',
    customer: 'Müşteri',
    product: 'Ürün',
    subproduct: 'Alt Ürün',
    category: 'Kategori',
    brand: 'Marka',
    supplier: 'Sağlayıcı',
  },
  profilePages: {
    payNow: 'Ödeme Yap',
    payments: 'Ödemeler',
    users: 'Kullanıcı Hesapları',
    onlineUsers: 'Çevrimiçi Kullanıcılar',
    modifyProfile: 'Profili Düzenle',
    newUser: 'Hesap Oluştur',
  },
  deskPages: {
    new: 'Yeni Talep',
    actual: 'Gerçekleşen Talepler',
  },
  notificationPages: {
    saved: 'Kayıtlı Bildirimler',
    regular: 'Düzenli Bildirimler',
  },
  settingPages: {
    service: 'Servis Ayarları',
    order: 'Sipariş Durum Ayarları',
    showcase: 'Vitrin',
  },
  forecastPages: {
    stock: 'Stok Tahmin',
    standard: 'Tahmin',
  },
  errors: 'Hatalar',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Grafikler',
  chart: {
    keyboardChart: 'Klavye Grafik',
    lineChart: 'Satır Grafik',
    mixChart: 'Karışık Grafik',
  },
};
