import Vue from 'vue';
import VueI18n from 'vue-i18n';

import vuetify from '@/locale/vuetify';
import en from './en';
import tr from './tr';

Vue.use(VueI18n);

const messages = {
  en: {
    ...en,
    $vuetify: vuetify.en,
  },
  tr: {
    ...tr,
    $vuetify: vuetify.tr,
  },
};

/**
  * Available locales
  */
export const locales = [{
  title: 'English',
  locale: 'en',
  abbr: 'ENG',
},
{
  title: 'Turkish',
  locale: 'tr',
  abbr: 'TUR',
},
];

const defaultLocale = 'tr';

/**
  * VueI18n instance
  */
const i18n = new VueI18n({
  locale: defaultLocale.locale,
  messages,
  silentTranslationWarn: true,
});

/**
  * Set locale to new value at Vue and Vuex.
  *
  * @param {Boolean} newLocale themeDark new value
  */
export async function setLocale(locale) {
  if (i18n.locale !== locale) {
    console.log(`[Locale] Set to "${locale}"`);
    i18n.locale = locale || defaultLocale.locale;
  } else {
    console.warn(`[Locale] "${locale}" is current`);
  }
}

export default i18n;
