import request from '@/api/utils';

export function getCurrent(formdata = {}, method = 'post') {
  const url = '/api/osos/current';
  return request.Api(url, formdata, method);
}

export function getConsumption(formdata = {}, method = 'post') {
  const url = '/api/osos/consumption';
  return request.Api(url, formdata, method);
}
export function getEndex(formdata = {}, method = 'post') {
  const url = '/api/osos/endex';
  return request.Api(url, formdata, method);
}
export function getOsosSayacList(formdata = {}, method = 'post') {
  const url = '/api/osos/sayac/list';
  return request.Api(url, formdata, method);
}