// eslint-disable-next-line import/no-cycle

import {
  getOsosSayacList,
} from '@/api/Request/osos';

const osos = {
  state: {
    ososSayacList: [],
  },
  getters: {
    ososSayacList: (state) => state.ososSayacList,

  },
  mutations: {
    SET_OSOS_SAYAC_LIST: (state, payload) => {
      state.ososSayacList = payload;
    },

  },
  actions: {

    ososSayac: async ({
      commit,
      state,
    }, payload = {}) => {
      try {

        if (state.ososSayacList.length > 0) return;
        getOsosSayacList(payload).then((res) => {

          if (res.status !== 200) return;
          if (res.data.success !== 'true') return;

          commit('SET_OSOS_SAYAC_LIST', res.data.data);
        });
      } catch (err) {
        console.log('SET_OSOS_SAYAC_LIST', err);
      }
    },

  },
};

export default osos;
