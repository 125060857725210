import farmLayout from '@/views/layout/Farmlist/TheLayout.vue';

const compayFarm = [

  {
    path: '/admin',
    component: farmLayout,
    name: 'adminfarmlist',
    children: [{
      path: '/admin/farmlist',
      alias: '/',
      component: () => import('@/views/page/admin/admin/Farmlist.vue'),
      name: 'adminfarmlist',
      meta: {
        title: 'Sahalar',
        icon: 'mdi-view-dashboard',
        roles: ['admin'],
      },
    }],
  },
];

export default compayFarm;
