export default {
  new: 'New',
  batch: 'Batch',
  pivot: 'Pivot',
  chart: 'Chart',
  list: 'List',
  cubeList: 'List',
  alert: 'Alert',
  delete: 'Delete',
  selectedCubeReset: 'Selected Cube Reset',
  allCubeReset: 'All Cube Reset',
  dataSource: 'Data Source',
  reportType: 'Report Type',
  date: 'Date',
  filterSort: 'Filter & Sort',
  chartTypes: 'Chart Types',
  save: 'Save',
  close: 'Close',
  cancel: 'Cancel',
  back: 'Back',
  continue: 'Continue',
  reset: 'Reset',
};
