<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      title: 'Datasmaty',
    };
  },
  metaInfo: {
    title: 'Datasmaty',

    titleTemplate: 'Datasmaty',
    link: [{ rel: 'favicon', href: '@/views/widget/images/datasmartylogo.svg' }],
  },
  meta: [
    { charset: 'utf-8' },
    {
      name: 'description',
      content:
        'Datasmarty Scada platformunun sunmuş olduğu gelişmiş üst düzey özellikleri ve yapay zeka teknolojisi ile enerji üretim santralleri, endüstriyel fabrikalar, veri merke­zleri, vb tesislerin dijitalleşmesini sağlar. Bu tesislerdeki kritik öneme sahip parametreleri sürekli izleyebilir ve yönetebilirsiniz. Gelişmiş analiz, raporlama ve alarm özellikleri ile tesisinizin yönetimini optimize edilebilirsiniz. Datasmarty mobil cihazlarla uyumlu ve tüm bilgiler, yüksek güvenlik teknolojisi ile korunmaktadı',
    },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  ],
  /*   head: {
    link: [
      {
        rel: "icon",
        href: require("@/views/widget/images/turkish.png")
      }
    ]
  } */
};
</script>
