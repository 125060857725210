export default {
  newCube: {
    header: 'New Cube',
    cubeName: 'Cube Name',
    placeholder: 'Enter cube name (such as Product, Brand)',
    measures: 'Measures',
    dimensions: 'Dimensions',
    trendsEnabled: 'Trends Enabled',
  },
  batchCube: {
    header: 'Create Batch Cube',
    dimensions: 'Dimensions',
    measures: 'Measures',
    combination: '(k) Combination',
    placeholder: 'Enter combination (such as 2,3,4,5 ..)',
    trendsEnabled: 'Trends Enabled',
    resetCube: 'Reset the Cube (if available)',
  },
  alert: {
    cubeSelection: 'Cube Selection',
    dimension: 'Dimension',
    measure: 'Measure',
    orderType: 'Order Type',
    rate: 'Rate',
    logical: 'Logical Operator',
    language: 'Language',
    value: 'Value',
    description: 'Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    step: 'Step',
  },
  delete: {
    question: 'Selected cube will be deleted, continue ?',
  },
  selectCubeReset: {
    question: 'Selected cube will be reset, continue ?',
  },
  allCubeReset: {
    question: 'All Cube will be reset, continue ?',
  },
  tableDeleteItem: {
    question: 'Selected Item will be deleted, continue ?',
  },
};
