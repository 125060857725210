import { getAdminCompany } from '@/api/Request/company';
// eslint-disable-next-line import/no-cycle
import helper from '@/api/helper';

const company = {
  state: {

    adminCompany: {},
  },
  getters: {
    adminCompany: (state) => state.adminCompany,
  },
  mutations: {

    SET_ADMIN_COMPANY: (state, payload) => {
      state.adminCompany = payload;
    },
  },
  actions: {

    getAdminCompany({ commit }, payload) {
      getAdminCompany(payload).then((res) => {
        if (helper.resIsEmpty(res)) {
          commit('SET_ADMIN_COMPANY', res.data.data);
        }
      });
    },
  },
};

export default company;
