import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'vue-pivottable/dist/vue-pivottable.css';
import VueMeta from 'vue-meta';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import ExportDocument from '@/views/components/Shared/ExportDocument.vue';
import Dialog from '@/views/components/app/Notify/Dialog.vue';
import Card from '@/views/components/app/Card/Card.vue';
import Button from '@/views/components/app/Form/Button.vue';
import Snackbar from '@/views/components/app/Notify/Snackbar.vue';
import Toolbar from '@/views/components/app/Bar/Toolbar.vue';
import TextField from '@/views/components/app/Form/TextField.vue';
import Select from '@/views/components/app/Form/Select.vue';
import MultiSelect from '@/views/components/app/Form/MultiSelect.vue';
import Switch from '@/views/components/app/Form/Switch.vue';
import Chips from '@/views/components/app/Element/Chips.vue';

import dev from '@/dev';
import vuetify from './plugins/vuetify';
import i18n from './locale';
import API from './api';
import App from './App.vue';
import router from './router';
import store from './store';

import './permission';

// import './registerServiceWorker';
Vue.config.productionTip = false;
Vue.prototype.$api = API;
Vue.use(Vuelidate);
Vue.component('Button', Button);
Vue.component('Dialog', Dialog);
Vue.component('Card', Card);
Vue.component('Snackbar', Snackbar);
Vue.component('Toolbar', Toolbar);
Vue.component('TextField', TextField);
Vue.component('Select', Select);
Vue.component('MultiSelect', MultiSelect);
Vue.component('SwitchComp', Switch);
Vue.component('Chips', Chips);
Vue.component('ExportDocument', ExportDocument);
Vue.prototype.$baseUrl = dev.baseUrl;
// eslint-disable-next-line import/prefer-default-export
export const eventBus = new Vue();
Vue.use(VueMeta);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
