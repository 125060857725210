<template>
  <v-text-field
    v-model="inputValue"
    :solo="solo"
    :outlined="outlined"
    :label="label"
    :placeholder="placeholder"
    :filled="filled"
    :rules="rules"
    :maxlength="maxlength"
    :counter="counter"
    :hint="hint"
    :dense="dense"
    :required="required"
    :disabled="disabled"
    :hide-details="hideDetails"
    :persistent-hint="persistentHint"
    @input="emitInput"
  >
    <slot />
  </v-text-field>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    solo: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    disabled: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    outlined: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    filled: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    dense: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },

    hideDetails: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    required: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    counter: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    // eslint-disable-next-line vue/prop-name-casing
    persistentHint: {
      type: String,
      default: () => (''),

    },
    rules: {
      type: Array,
      default: () => [],
    },
    otherDisplay: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => (false),
    },
    value: {},
  },
  data() {
    return {
      inputValue: null,
    };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    emitInput() {
      this.$emit('input', this.inputValue);
    },
  },

};
</script>

<style></style>
