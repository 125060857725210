<template>
  <div>
    <v-menu
      v-if="isMobile()"
      offset-y
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="farmlistreflesFunc">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-refresh
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Yenile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openSetting('settig')">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-settings
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ayarlar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openList('error')">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-bell
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Hata</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openList('errorGroup')">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-bell
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Hata Grup</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="openroot !== 'mapslist'"
          @click="openList('mapslist')"
        >
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-view-list
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Harita Görünüm</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="openroot !== 'farmlist'"
          @click="openList('farmlist')"
        >
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-view-list
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Liste Görünüm</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click.stop="drawer = !drawer">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-open-in-app
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Saha Seç</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else>
      <v-btn
        text
        color="primary"
        @click="farmlistreflesFunc"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="openSetting('settig')"
      >
        <v-icon>mdi-settings</v-icon>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="openList('error')"
      >
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="openList('errorGroup')"
      >
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn
        v-if="openroot !== 'mapslist'"
        text
        color="primary"
        @click="openList('mapslist')"
      >
        <v-icon>mdi-view-list</v-icon>
        Harita Görünüm
      </v-btn>

      <v-btn
        v-if="openroot !== 'farmlist'"
        text
        color="primary"
        @click="openList('farmlist')"
      >
        <v-icon>mdi-view-list</v-icon>
        Liste Görünüm
      </v-btn>

      <v-btn
        text
        color="primary"
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-open-in-app</v-icon>
        Saha Seç
      </v-btn>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      hide-overlay
      right
      height="100vh"
      class="mb-10"
    >
      <v-card class="ml-0 mr-0 pr-0 pl-0 pb-0 mb-0">
        <div>
          <div class="font-weight-bold ml-2 mt-2 pl-2 pt-2 pb-2">
            Santral Listesi
          </div>
          <v-text-field
            outlined
            class="ml-2 mr-2"
            dense
            label="Ara"
            append-outer-icon="mdi-file-find"
            hide-details
            @click:append-outer="searchFarm"
          />
        </div>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="width: 20px"
                  class="text-left font-weight-black"
                >
                  Seç
                </th>
                <th class="text-left font-weight-black">
                  İsim
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in santralList"
                :key="item.companyCode + '_' + item.farmName"
                :class=" item.prefix === selectedprefix ? 'selectedfarm' : ''"
              >
                <td>
                  <v-btn
                    small
                    class=""
                    icon
                    color="blue darken-1"
                    @click="selectedFarm(item)"
                  >
                    <v-icon>mdi-launch</v-icon>
                  </v-btn>
                </td>
                <td>{{ item.companyName }}/{{ item.farmName }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getCompanyFarmList } from '@/api/Request/farm';
import { getCompanyList } from '@/api/Request/company';

export default {
  name: 'AppProfile',
  data() {
    return {
      selectedprefix: null,
      santralList: [],
      companyList: [],
      companyCodeModel: '',
      drawer: null,
      openroot: '',
      currentBreakpoint: 'lg',
    };
  },
  computed: {},
  created() {
    this.openroot = this.$route.name;
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    // Event listener'ı kaldırın
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    setTimeout(() => {
      this.getCompanyList();
    }, 1000);
  },
  methods: {
    handleResize() {
      // Ekran boyutu değiştiğinde bu metot tetiklenir
      this.currentBreakpoint = this.$vuetify.breakpoint.name;
    },
    isMobile() {
      this.handleResize();
      return this.currentBreakpoint === 'xs' || this.currentBreakpoint === 'sm' || this.currentBreakpoint === 'md'; // Mobil olarak kabul edilecek ekran genişliği eşik değeri
    },
    farmlistreflesFunc() {
      this.$store.dispatch('setFarmlistrefles');
    },
    openList(name) {
      console.log('openList', name);
      this.$router.push(`/${name}`);
      this.openroot = name;
    },
    openSetting(name) {
      console.log('setting', name);
      this.$router.push('/admin/setting/info');
      this.openroot = name;
    },
    searchFarm() { },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    getCompanyList() {
      const params = {};
      const storage = window.localStorage;
      getCompanyList(params).then((res) => {
        if (res.data.success === 'true') {
          if (res.data.data.length > 0) {
            const companyList = [];

            this.$store.dispatch('setCompany', res.data.data[0]);

            Object.keys(res.data.data).forEach((i) => {
              companyList.push({
                text: res.data.data[i].companyName,
                value: res.data.data[i].companyCode,
              });
            });
            this.companyList = companyList;
            // eslint-disable-next-line prefer-destructuring

            const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
            this.companyCodeModel = userCompany.companyCode;
            this.selectedprefix = userCompany.prefix;

            this.getFarmList();
          }
        }
        // self.getUserList();
      });
    },
    getFarmList() {
      const params = {};

      params.condiniton = {
        companyCodeList: this.$store.getters.companyCodeList,
        status: 'Active',
      };

      getCompanyFarmList(params).then((res) => {
        if (res.data.success === 'true') {
          if (res.data.data.length === 0) {
            return;
          }
          this.santralList = res.data.data;
          this.$store.dispatch('setFarmdata', res.data.data);
          this.$store.dispatch('setFarmList', res.data.data);

          /*  Object.keys(res.data.data).forEach((index) => {
            this.santralList.push(res.data.data[index]);
          }); */

          // eslint-disable-next-line no-shadow
          const params = {
            /*  condiniton: {
              prefix: this.selectedprefix,
            }, */
          };

          // this.$store.dispatch('getCompany');
          this.$store.dispatch('getAdminCompany');
          this.$store.dispatch('getMonthly');
          this.$store.dispatch('getStatsMeasure');
          this.$store.dispatch('getClientYearList');
          this.$store.dispatch('getSantralData', params);
          this.$store.dispatch('setDataloggerData', params);
          this.$store.dispatch('setInverterData', params);
          this.$store.dispatch('setStringboxData', params);
          this.$store.dispatch('setSensorData', params);
        }
      });
    },

    selectedFarm(item) {
      const storage = window.localStorage;
      storage.setItem('selectedprefix', window.btoa(item.prefix));

      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix,
      };

      this.$store.dispatch('setSelectedFarm', temp);
      storage.setItem('user_company', window.btoa(JSON.stringify(temp)));
      window.location.reload();
    },
  },
};
</script>

<style scoped>
#btnModifyProfile:hover {
  color: orange !important;
  background-color: white !important;
  border: 2px solid orange;
}

#btnNewUser:hover {
  color: green !important;
  background-color: white !important;
  border: 2px solid green;
}

.selectedfarm {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(165, 165, 165) !important;
}
</style>
