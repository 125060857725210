import {
  getDataloggerList,
} from '@/api/Request/datalogger';
import {
  getInverterList,
} from '@/api/Request/inverter';

import {
  getStringboxList,
} from '@/api/Request/stringbox';

import {
  getSensorList,
} from '@/api/Request/sensor';

// eslint-disable-next-line import/no-cycle
import helper from '@/api/helper';

const farmStore = {
  state: {
    company: {},
    santral: {},
    selectedComp: {},
    farmData: {},
    farmlist: {},
    dataloggerData: {},
    santralDataloggerData: {},
    inverterData: {},
    santralInverterData: {},
    inverterMeasureList: {},
    stringboxData: {},
    santralStringboxData: {},
    stringboxMeasureList: {},
    sensorData: {},
    santralSensorData: {},
    sensorMeasureList: {},
    selectedFarm: {
      companyCode: '',
      prefix: '',
    },

  },
  getters: {
    company: (state) => state.company,
    santral: (state) => state.santral,
    farmlist: (state) => state.farmlist,
    selectedComp: (state) => state.selectedComp,
    farmData: (state) => state.farmData,
    dataloggerData: (state) => state.dataloggerData,
    santralDataloggerData: (state) => state.santralDataloggerData,
    inverterData: (state) => state.inverterData,
    santralInverterData: (state) => state.santralInverterData,
    inverterMeasureList: (state) => state.inverterMeasureList,
    stringboxData: (state) => state.stringboxData,
    santralStringboxData: (state) => state.santralStringboxData,
    stringboxMeasureList: (state) => state.stringboxMeasureList,
    santralSensorData: (state) => state.santralSensorData,
    sensorData: (state) => state.sensorData,
    sensorMeasureList: (state) => state.sensorMeasureList,
    selectedFarm: (state) => state.selectedFarm,
  },
  mutations: {
    SET_SELECTED_COMP: (state, payload) => {
      state.selectedComp = payload;
    },
    SET_COMPANY: (state, payload) => {
      state.company = payload;
    },

    SET_SANTRAL: (state, payload) => {
      state.santral = payload;
    },
    SET_FARMDATA: (state, payload) => {
      state.farmData = payload;
    },
    SET_FARM_LIST: (state, payload) => {
      state.farmlist = payload;
    },
    SET_DATALOGGERDATA: (state, payload) => {
      state.dataloggerData = payload;
    },
    SET_SANTRAL_DATALOGGERDATA: (state, payload) => {
      state.santralDataloggerData = payload;
    },

    SET_INVERTERDATA: (state, payload) => {
      state.inverterData = payload;
    },
    SET_SANTRAL_INVERTERDATA: (state, payload) => {
      state.santralInverterData = payload;
    },
    SET_INVERTER_MEASURES: (state, payload) => {
      state.inverterMeasureList = payload;
    },
    SET_STRINGBOX_DATA: (state, payload) => {
      state.stringboxData = payload;
    },
    SET_SANTRAL_STRINGBOX_DATA: (state, payload) => {
      state.santralStringboxData = payload;
    },
    SET_STRINGBOX_MEASURES: (state, payload) => {
      state.stringboxMeasureList = payload;
    },
    SET_SENSOR_DATA: (state, payload) => {
      state.sensorData = payload;
    },
    SET_SANTRAL_SENSOR_DATA: (state, payload) => {
      state.santralSensorData = payload;
    },
    SET_SENSOR_MEASURES: (state, payload) => {
      state.sensorMeasureList = payload;
    },
    SET_SELECTED_FARM: (state, payload) => {
      state.selectedFarm = payload;
    },
  },
  actions: {
    setDataloggerData: async (context, payload) => {
      getDataloggerList(payload).then((res) => {
        if (res.data.success === 'true') {
          if (!helper.is_Empty(res.data.data)) {
            context.commit('SET_DATALOGGERDATA', res.data.data);
            const temp = {};
            Object.keys(res.data.data).forEach((i) => {
              const {
                santral,
                santralCode,
              } = res.data.data[i];

              if (helper.is_Empty(temp[santral])) {
                temp[santralCode] = [];
              }
              temp[santralCode].push(res.data.data[i]);
            });

            context.commit('SET_SANTRAL_DATALOGGERDATA', temp);
          }
        }
      });
    },
    setInverterData: async (context, payload) => {
      getInverterList(payload).then((res) => {
        if (!helper.resIsEmpty(res)) {
          return;
        }

        context.commit('SET_INVERTERDATA', res.data.data);
        const temp = {};
        const tempMeasures = [];
        const measuresList = [];
        // eslint-disable-next-line no-underscore-dangle
        const _t = ['sum', 'avg', '1'];
        Object.keys(res.data.data).forEach((i) => {
          const val = res.data.data[i];

          const {
            santralCode,
            INVERTER,
            // eslint-disable-next-line camelcase
            general_measure_list,
          } = val;

          if (!helper.is_Empty(INVERTER)) {
            if (helper.is_Empty(temp[santralCode])) {
              temp[santralCode] = {};
            }
            if (helper.is_Empty(temp[santralCode][INVERTER])) {
              temp[santralCode][INVERTER] = {};
            }
            temp[santralCode][INVERTER] = val;

            if (!helper.is_Empty(general_measure_list)) {
              // eslint-disable-next-line no-shadow
              Object.keys(general_measure_list).forEach((i) => {
                if (measuresList.indexOf(general_measure_list[i].value) === -1
                    && _t.indexOf(general_measure_list[i].formuletype) > -1) {
                  tempMeasures.push(general_measure_list[i]);
                  measuresList.push(general_measure_list[i].value);
                }
              });
            }
          }
        });

        context.commit('SET_SANTRAL_INVERTERDATA', temp);
        context.commit('SET_INVERTER_MEASURES', tempMeasures);
      });
    },
    setStringboxData: async (context, payload) => {
      getStringboxList(payload).then((res) => {
        if (!helper.resIsEmpty(res)) {
          return;
        }

        context.commit('SET_STRINGBOX_DATA', res.data.data);
        const temp = {};
        const tempMeasures = [];
        const measuresList = [];
        // eslint-disable-next-line no-underscore-dangle
        const _t = ['sum', 'avg', '1'];
        Object.keys(res.data.data).forEach((i) => {
          const {
            santralCode,
            STRINGBOX,
            // eslint-disable-next-line camelcase
            general_measure_list,
          } = res.data.data[i];

          if (!helper.is_Empty(STRINGBOX)) {
            if (helper.is_Empty(temp[santralCode])) {
              temp[santralCode] = {};
            }
            if (helper.is_Empty(temp[santralCode][STRINGBOX])) {
              temp[santralCode][STRINGBOX] = {};
            }
            temp[santralCode][STRINGBOX] = res.data.data[i];
            Object.keys(general_measure_list).forEach((i) => {
              if (measuresList.indexOf(general_measure_list[i].value) === -1
                    && _t
                      .indexOf(general_measure_list[i].formuletype) > -1) {
                tempMeasures.push(general_measure_list[i]);
                measuresList.push(general_measure_list[i].value);
              }
            });
          }
        });

        context.commit('SET_SANTRAL_STRINGBOX_DATA', temp);
        context.commit('SET_STRINGBOX_MEASURES', tempMeasures);
      });
    },

    setSensorData: async (context, payload) => {
      getSensorList(payload).then((res) => {
        if (res.data.success === 'true') {
          if (!helper.is_Empty(res.data.data)) {
            context.commit('SET_SENSOR_DATA', res.data.data);
            const temp = {};
            const tempMeasures = [];
            const measuresList = [];
            // eslint-disable-next-line no-underscore-dangle
            const _t = ['sum', 'avg'];
            Object.keys(res.data.data).forEach((i) => {
              const {
                santral,
                SENSOR,
                // eslint-disable-next-line camelcase
                general_measure_list,
              } = res.data.data[i];
              if (helper.is_Empty(temp[santral])) {
                temp[santral] = {};
              }
              if (helper.is_Empty(temp[santral][SENSOR])) {
                temp[santral][SENSOR] = {};
              }
              temp[santral][SENSOR] = res.data.data[i];

              Object.keys(general_measure_list).forEach((i) => {
                if (measuresList.indexOf(general_measure_list[i].value) === -1 && _t
                  .indexOf(general_measure_list[i].formuletype) > -1) {
                  tempMeasures.push(general_measure_list[i]);
                  measuresList.push(general_measure_list[i].value);
                }
              });
            });

            context.commit('SET_SANTRAL_SENSOR_DATA', temp);
            context.commit('SET_SENSOR_MEASURES', tempMeasures);
          }
        }
      });
    },
    setSelectedComp: async (context, payload) => {
      context.commit('SET_SELECTED_COMP', payload);
    },
    setCompany: async (context, payload) => {
      if (!helper.is_Empty(payload)) {
        context.commit('SET_COMPANY', payload);
      }
    },
    setFarmdata: async (context, payload) => {
      if (!helper.is_Empty(payload)) {
        context.commit('SET_FARMDATA', payload[0]);
      }
    },
    setFarmList: async (context, payload) => {
      if (!helper.is_Empty(payload)) {
        context.commit('SET_FARM_LIST', payload);
      }
    },
    setSantral: async (context, payload) => {
      context.commit('SET_SANTRAL', payload);
    },
    setSelectedFarm: async (context, payload) => {
      context.commit('SET_SELECTED_FARM', payload);
    },
  },
};

export default farmStore;
