<template>
  <v-switch
    v-model="inputValue"
    :label="label"
    :inset="inset"
    :hide-details="hideDetails"
    @change="emitChange"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    inset: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    value: {},
  },
  data() {
    return {
      inputValue: null,
    };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    emitChange() {
      this.$emit('input', this.inputValue);
    },
  },

};
</script>
