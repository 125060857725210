import route from './route';
import button from './button';
import modal from './modal';
import global from './global';
import header from './header';

export const index = {
  route,
  button,
  modal,
  global,
  header,
  // Toolbar
  toolbar: {
    appname: 'smartydata',
    settings: 'Settings',
    profile: 'Profile',
    logout: 'Logout',
    turkish: 'Turkish',
    english: 'English',
  },
  settings: {
    title: 'Theme Settings',
    default: 'Set Default',
    position: 'Position of elements',
    theme: 'Theme',
    dark: 'Dark theme',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  login: {
    titleIn: 'Sign In Form',
    titleUn: 'Sign Un Form',
    singIn: 'Sign in',
    singUp: 'Sign up',
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm',
  },
  errors: {
    whoops: 'Whoops',
    back: 'Get me out of here!',
    301: 'Moved Permanently',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for '
      + 'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide',
  },
  ui: {
    switch: 'Switch Language',
    theme: 'Switch Theme',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    primary: 'Primary',
    secondary: 'Secondary',
    default: 'Default',
    accent: 'Accent',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
  },
};

export default index;
