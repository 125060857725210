import request from '@/api/utils';

export function list(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/list';
  return request.Api(url, formdata, method);
}

export function santralList(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/santralList';
  return request.Api(url, formdata, method);
}

export function sensorinfo(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/sensorinfo';
  return request.Api(url, formdata, method);
}

export function setSensor(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/setSensor';
  return request.Api(url, formdata, method);
}

export function delSensor(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/delSensor';
  return request.Api(url, formdata, method);
}

export function getSensorList(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/getSensorList';
  return request.Api(url, formdata, method);
}

export function getSensorData(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/santralList';
  return request.Api(url, formdata, method);
}

export function getFarmSensorMinuteData(formdata = {}, method = 'post') {
  const url = '/api/sensorbox/farmMinute';
  return request.Api(url, formdata, method);
}


