<template>
  <v-card
    :outlined="outlined"
    :width="width"
    :height="height"
    :elevation="elevation"
    :loading="loading"
    :rounded="rounded"
    v-bind="attrs"
    v-on="on"
  >
    <slot name="cardTitle" />
    <slot name="cardText" />
    <slot name="divider" />
    <slot name="cardAction" />
    <slot />
  </v-card>
</template>

<script>

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 1,
    },
    height: {},
    width: {},
    attrs: {
      default: false,
    },
    on: {
      default: false,
    },
    outlined: {},
    rounded: {
      type: String,
      default: () => 'lg',
    },
  },
  data() {
    return {};
  },

};
</script>

<style></style>
