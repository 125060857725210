<template>
  <v-chip
    class="ma-2"
    :small="payload.small"
    :large="payload.large"
    :x-small="payload.xSmall"
    :color="payload.color"
    :text-color="payload.textColor"
  >
    {{ payload.text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: () => ({
        color: '',
        text: '',
        textColor: '',
        small: false,
        xSmall: false,
        large: false,
      }),
    },
  },
};
</script>
