import { getAlarmList, setAlarm } from '@/api/Request/alarm';
// eslint-disable-next-line import/no-cycle
import helper from '@/api/helper';
import i18n from '@/locale';

const alarm = {
  state: {
    alarmlist: {},
    severity: [
      { text: 'Düşük', value: '1', key: 'low' },
      { text: 'Orta', value: '2', key: 'middle' },
      { text: 'Yüksek', value: '3', key: 'high' },
      { text: 'Kritik', value: '4', key: 'critical' },
    ],
    alarmCodeList: [
      { text: i18n.t('Cihaz Erişimi'), value: 'DeviceAccess' },
      { text: i18n.t('İnverter Hata Uyarısı'), value: 'InverterErrorWarning' },
      { text: i18n.t('Kullanıcı Tanımlı Alarm'), value: 'UserDefinedAlarms' },
      { text: i18n.t('Veri Kaydedici Erişim'), value: 'DataLoggerAccess' },
    ],

  },
  getters: {
    alarmlist: (state) => state.alarmlist,
    severity: (state) => state.severity,
    alarmCodeList: (state) => state.alarmCodeList,
  },
  mutations: {
    SET_ALARM: (state, payload) => {
      state.alarmlist = payload;
    },
  },
  actions: {
    getAlarmList({ commit }, payload) {
      getAlarmList(payload).then((res) => {
        if (helper.resIsEmpty(res)) {
          commit('SET_ALARM', res.data.data);
        }
      });
    },
    setSelectedAlarm({ state }, payload) {
      setAlarm(payload).then((res) => {
        console.log(res);
      });
    },

  },
};

export default alarm;
