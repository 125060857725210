import request from '@/api/utils';

export function setDataloggerCreate(formdata = {}, method = 'post') {
  const url = '/api/datalogger/create';
  return request.Api(url, formdata, method);
}

export function setDataloggerEdit(formdata = {}, method = 'post') {
  const url = '/api/datalogger/edit';
  return request.Api(url, formdata, method);
}

export function setDataloggerDelete(formdata = {}, method = 'post') {
  const url = '/api/datalogger/del';
  return request.Api(url, formdata, method);
}

export function getDataloggerList(formdata = {}, method = 'post') {
  const url = '/api/datalogger/list';
  return request.Api(url, formdata, method);
}
