<template>
  <v-toolbar
    :color="color"
    :elevation="elevation"
    :height="height"
    :flat="flat"
  >
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <slot />
  </v-toolbar>
</template>

<script>

export default {
  props: {
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    elevation: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: '20px',
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

};
</script>

<style></style>
