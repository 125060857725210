<template>
  <div class="v-application--wrap">
    <the-layout-toolbar />
    <the-layout-content />
    <the-layout-fab />
    <the-layout-to-top-fab />
    <the-layout-footer />
  </div>
</template>

<script>
import TheLayoutToolbar from '../TheLayoutToolbar.vue';
import TheLayoutFab from '../TheLayoutFab.vue';
import TheLayoutToTopFab from '../TheLayoutToTopFab.vue';
import TheLayoutFooter from '../TheLayoutFooter.vue';
import TheLayoutContent from '../TheLayoutContent.vue';

export default {
  name: 'TheLayout',
  components: {
    TheLayoutToolbar,
    TheLayoutFab,
    TheLayoutContent,
    TheLayoutToTopFab,
    TheLayoutFooter,
  },
  data: () => ({}),
};
</script>
