<template>
  <div class="v-application--wrap">
    <the-layout-drawer :permission-routes="settingRoute" />
    <the-layout-toolbar />
    <the-layout-content />
    <the-layout-fab />
    <the-layout-to-top-fab />
    <the-layout-footer />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import settingRoute from '@/router/modules/setting';
import TheLayoutDrawer from '../TheLayoutDrawer.vue';
import TheLayoutToolbar from '../TheLayoutToolbar.vue';
import TheLayoutFab from '../TheLayoutFab.vue';
import TheLayoutToTopFab from '../TheLayoutToTopFab.vue';
import TheLayoutFooter from '../TheLayoutFooter.vue';
import TheLayoutContent from '../TheLayoutContent.vue';

export default {
  name: 'TheLayout',
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
    TheLayoutContent,
    TheLayoutFab,
    TheLayoutToTopFab,
    TheLayoutFooter,
  },
  data: () => ({ settingRoute }),
};
</script>
