import farmLayout from '@/views/layout/Farmlist/TheLayout.vue';
import stationLayout from '@/views/layout/Station/TheLayout.vue';

const settingRoute = [{
  path: '/mapslist',
  component: farmLayout,
  name: 'mapslist',
  children: [{
    path: '/mapslist',
    alias: '/',
    component: () => import('@/views/page/Farm/GoogleMaps.vue'),
    name: 'mapslist',
    meta: {
      title: 'Sahalar',
      icon: 'mdi-view-dashboard',
    },
  }],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/company',
    alias: '/',
    component: () => import('@/views/page/admin/station/Company/Info.vue'),
    name: 'adminstationcompany',
    meta: {
      title: 'Şirket Bilgileri',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],
    },
  }],
},
{
  path: '/error',
  component: farmLayout,
  name: 'farmerror',
  hidden: true,
  children: [{
    path: '/error',
    alias: '/',
    component: () => import('@/views/page/detail/Notification/FarmError.vue'),
    name: 'farmerror',
    meta: {
      title: 'Alarmlar',
      icon: 'mdi-view-dashboard',
      roles: ['admin', 'manager'],
    },
  }],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/farm',
    alias: '/',
    component: () => import('@/views/page/admin/station/Farm/Info.vue'),
    name: 'adminstationfarm',
    meta: {
      title: 'Saha Bilgileri',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],
    },
  }],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/santral',
    alias: '/',
    component: () => import('@/views/page/admin/station/Santral/Add.vue'),
    name: 'adminstationsantral',
    meta: {
      title: 'Santral',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],
    },
  }],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/datalogger',
    alias: '/',
    component: () => import('@/views/page/admin/station/Datalogger/Index.vue'),
    name: 'adminstationdatalogger',
    meta: {
      title: 'Datalogger',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],
    },
  }],
},
/* {
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/datalogger1',
    alias: '/',
    component: () => import('@/views/page/admin/station/Datalogger/Index1.vue'),
    name: 'adminstationdatalogger1',
    meta: {
      title: 'Datalogger1',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],
    },
  }],
}, */
{
  path: '/',
  component: stationLayout,
  name: 'adminstationdevice',
  meta: {
    title: 'Cihaz',
    icon: 'mdi-view-dashboard',
    affix: true,
    roles: ['admin', 'manager'],

  },
  children: [{
    path: '/admin/station/inverter',
    alias: '/',
    component: () => import('@/views/page/admin/station/Inverter/Add.vue'),
    name: 'adminstationinverter',
    meta: {
      title: 'Inverter Ekle',
      roles: ['admin', 'manager'],

    },
  },
  {
    path: '/admin/station/stringbox',
    alias: '/',
    component: () => import('@/views/page/admin/station/Stringbox/Add.vue'),
    name: 'adminstationstringbox',
    meta: {
      title: 'Stringbox Ekle',
      roles: ['admin', 'manager'],

    },
  },
  {
    path: '/admin/station/sensor',
    alias: '/',
    component: () => import('@/views/page/admin/station/Sensor/Add.vue'),
    name: 'adminstationsensor',
    meta: {
      title: 'Sensor Ekle',
      roles: ['admin', 'manager'],

    },
  },
  ],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/alarm',
    alias: '/',
    component: () => import('@/views/page/admin/station/Alarm/AlarmList.vue'),
    name: 'adminstationworkorder',
    meta: {
      title: 'Alarmlar',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],

    },
  }],
},
{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/workorder',
    alias: '/',
    component: () => import('@/views/page/admin/station/WorkOrder/Index.vue'),
    name: 'adminstationworkorder',
    meta: {
      title: 'İş Emri',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],

    },
  }],
},

{
  path: '/',
  component: stationLayout,
  name: 'adminstation',
  children: [{
    path: '/admin/station/formule',
    alias: '/',
    component: () => import('@/views/page/admin/station/Formule/Index.vue'),
    name: 'adminstationFormule',
    meta: {
      title: 'Formül',
      icon: 'mdi-view-module',
      roles: ['admin', 'manager'],

    },
  }],
},
];

export default settingRoute;
