<template>
  <v-btn
    :class="btncls"
    :height="height"
    :min-height="minHeight"
    :width="width"
    :min-width="minWidth"
    :style="buttonStyle"
    :color="color"
    :text="text"
    :icon="icon"
    :outlined="outlined"
    :disabled="disabled"
    :large="large"
    :loading="loading"
    :small="small"
    :fab="fab"
    :x-small="x_small"
    :elevation="elevation"
    :value="value"
    @click="clickFunc"
  >
    <slot />
    {{ $t(buttonText) }}
  </v-btn>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: () => (''),
    },
    // eslint-disable-next-line vue/require-default-prop
    color: String,
    text: Boolean,
    icon: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    large: Boolean,
    loading: Boolean,
    small: Boolean,
    fab: Boolean,
    elevation: [Number, String],
    x_small: Boolean,
    height: [Number, String],
    width: [Number, String],
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    btncls: String,
    minWidth: [Number, String],
    minHeight: [Number, String],
    value: null,
  },
  methods: {
    clickFunc() {
      this.$emit('click');
    },
  },
};
</script>
