// eslint-disable-next-line import/no-cycle
import request from '../../api/utils';

const common = {
  state: {
    sensorList: [],
    sensorMeasures: [
      {
        text: 'Irradiation', value: 'Irradiation', unit: 'W/m2', type: 'float', min: 0, max: 1300, formuletype: 'avg',
      },
      {
        text: 'TempBoard', value: 'TempBoard', unit: '℃', type: 'float', min: -40, max: 85, formuletype: 'avg',
      },
      {
        text: 'SensorTemp', value: 'SensorTemp', unit: '℃', type: 'float', min: -40, max: 85, formuletype: 'avg',
      },
    ],
  },
  getters: {
    sensorList: (state) => state.sensorList,
    sensorMeasures: (state) => state.sensorMeasures,

  },
  mutations: {
    SET_SENSOR_LIST: (state, payload) => {
      state.sensorList = payload;
    },

  },
  actions: {

    getSensorList: async ({
      commit,
    }) => {
      try {
        const req = request.Api('/api/sensorbox/sensorinfo', {}, 'post');

        req.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success !== 'true') return;

          commit('SET_SENSOR_LIST', res.data.data);
        });
      } catch (err) {
        console.log('SET_SENSOR_LIST', err);
      }
    },

  },
};

export default common;
