export default {
  ActiveIn: 'Aktif Çekiş',
  ReactiveInductiveIn: 'Reaktif İndüktif Çekiş',
  ReactiveCapasitiveIn: 'Reaktif Kapasitif Çekiş',
  ActiveOut: 'Aktif Veriş',
  ReactiveInductiveOut: 'Reaktif İndüktif Veriş',
  ReactiveCapasitiveOut: 'Reaktif Kapasitif Veriş',
  Multiplier: 'Çarpan',
  ActiveInAdd: 'Tüketim Ek',
  ActiveOutAdd: 'Üretim Ek',
  status: 'Durum',
  ReactiveInductiveMulInActiveIn: 'Çekiş R.Ind./Tüketim Oran',
  ReactiveCapasitiveInMulInActiveIn: 'Çekiş R.Kap./Tüketim Oran',
  ReactiveInductiveOutMulActiveOut: 'Veriş R.Ind./Üretim Oran',
  ReactiveCapasitiveOutMulActiveOu: 'Veriş R.Kap./Üretim Oran',
  Installation: 'Abone',
  Title: 'Ünvan',
  ProfileDate: 'Profil Tarihi',
  TTotalIn: 'T Toplam Çekiş',
  TTotalOut: 'T Toplam Veriş',
  RIIn: 'RI Çekiş',
  RCOut: 'RC Veriş',
  Current1: 'Akım 1',
  Current2: 'Akım 2',
  Current3: 'Akım 3 Veriş',
  Voltage1: 'Voltaj 1',
  Voltage2: 'Voltaj 2',
  Voltage3: 'Voltaj 3',
  Cosf1: 'Cosf 1',
  Cosf2: 'Cosf 2',
  Cosf3: 'Cosf 3',
  EndexDate: 'Endeks Tarihi',
  TTotal: 'T Toplam',
  T1: 'T1',
  T2: 'T2',
  T3: 'T3',
  Demand: 'Demand',
  DemandDate: 'Demand Tarihi',
  RI: 'RI',
  RC: 'RC',
  RIOut: 'RI Veriş',
  T1Out: 'T1 Veriş',
  T2Out: 'T2 Veriş',
  T3Out: 'T3 Veriş',
  DemandOut: 'Demand Veriş',
  DemandOutDate: 'Demand Tarihi Veriş',
  Meterpoint: 'Sayaç noktası',
};
