<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :label="label"
    :solo="solo"
    :outlined="outlined"
    :filled="filled"
    :dense="dense"
    :autofocus="autofocus"
    :hide-details="hideDetails"
    :no-data-text="$t('no_data_available')"
    @change="emitChange"
  >
    <slot />
  </v-select>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: () => (null),
    },
    solo: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    value: {},
  },
  data: () => ({
    selectedItem: '',
  }),
  watch: {
    value(value) {
      this.selectedItem = value;
    },
  },
  mounted() {
    this.selectedItem = this.value;
  },
  methods: {
    emitChange() {
      this.$emit('input', this.selectedItem);
    },
  },
};
</script>
