<template>
  <v-snackbar
    v-model="notify.snackbar"
    :color="notify.color"
    :vertical="notify.vertical"
  >
    <span class="text-h5"> {{ notify.text }}</span>

    <template v-slot:action="{attrs}">
      <Button
        color="white"
        :text="true"
        v-bind="attrs"
        @click="notify.snackbar = false"
      >
        Kapat
      </Button>
    </template>
  </v-snackbar>
</template>
<script>

export default {

  props: {
    notify: {
      type: Object,
      default: () => ({
        snackbar: false,
        vertical: true,
        color: 'success',
        text: '',
      }),
    },
  },
};
</script>
