import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import syncStorage from './plugins/syncStorage';
import permission from './modules/permission';
import settings from './modules/settings';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
// eslint-disable-next-line import/no-cycle
import common from './modules/common';
import sensor from './modules/sensor';
// eslint-disable-next-line import/no-cycle
import santral from './modules/santral';
// eslint-disable-next-line import/no-cycle
import farm from './modules/farm';
// eslint-disable-next-line import/no-cycle
import alarm from './modules/alarm';
import osos from './modules/osos';
// eslint-disable-next-line import/no-cycle
import company from './modules/company';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
    sensor,
    common,
    santral,
    farm,
    alarm,
    osos,
    company,
  },

  plugins: [
    syncStorage({}),
  ],
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
