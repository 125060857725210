// eslint-disable-next-line import/no-cycle
import user from '@/store/modules/user';
import settings from '@/store/modules/settings';
// eslint-disable-next-line import/no-cycle
import helper from '@/api/helper';

class SyncStorage {
  constructor(syncStorageOption) {
    this.storage = syncStorageOption.storage || (window && window.localStorage);
    this.prefix = syncStorageOption.prefix || 'vuex_';

    this.user = 'user';
    this.settings = 'settings';
    this.userMutations = this.getModuleOptions(user, 'mutations');
    this.userActions = this.getModuleOptions(user, 'actions');
    this.settingsMutations = this.getModuleOptions(settings, 'mutations');
    this.settingsActions = this.getModuleOptions(settings, 'actions');
  }

  /** vuex plugin function */
  subscribe = async (store) => {
    if (!this.checkStorage()) {
      throw new Error('[vuex.SyncStorage] Invalid "Storage" instance given');
    }

    // init and apply user state from storage
    if (this.initUserState(store)) {

      /* const accessedRoutes = await store.dispatch('GenerateRoutes', {
        roles: store.getters.roles,
      });

      router.addRoutes(accessedRoutes, {
        override: true,
      }); */
    } else {
      console.warn('[vuex.SyncStorage] No user state in "Storage"');
    }

    // init and apply settings state from storage
    if (this.initSettingsState(store)) {
      // console.log('[vuex.SyncStorage] initSettingsState');
    } else {
      // console.warn('[vuex.SyncStorage] No user settings in "Storage"');
    }

    store.subscribe((mutation, state) => {
      // console.logo('storage subscribe ', mutation.type);
      if (this.userMutations.includes(mutation.type)) {
        // console.logo('storage subscribe user_mutations', mutation, state);
        // TODO kullanıcı verilerini localstore kayıt işlemi
        //  this.setToStorage(`${this.prefix}${this.user}`, JSON.stringify(state.user));
      }
      if (this.settingsMutations.includes(mutation.type)) {
        // console.logo('storage subscribe settings_mutations', mutation, state);
        this.setToStorage(`${this.prefix}${this.settings}`, JSON.stringify(state.settings));
      }
    });
  };

  /** declare methods */
  // Get array of module matation types
  getModuleOptions = (module, key) => {
    if (!module || !module[key]) return [];
    return Object.keys(module[key]);
  };

  // Check LocalStorage to read/write
  checkStorage() {
    // console.time('checkStorage');s
    try {
      this.storage.setItem(`${this.prefix}@@`, 1);
      this.storage.removeItem(`${this.prefix}@@`);
    } catch (e) {
      // console.error(`Check storage failed: ${e}`);
      return false;
    }
    // console.timeEnd('checkStorage');
    return true;
  }

  initCompany(store) {
    // eslint-disable-next-line camelcase
    const user_company = this.getFromStorage('user_company');

    // eslint-disable-next-line camelcase
    if (user_company !== undefined && user_company !== null) {
      // eslint-disable-next-line no-underscore-dangle
      const _company = JSON.parse(atob(user_company));
      store.commit('SET_SELECTED_FARM', _company);
    }
  }

  initUserState(store) {
    const userState = this.getFromStorage(`${this.prefix}${this.user}`);
    this.initCompany(store);
    if (userState !== undefined && userState !== null) {
      // eslint-disable-next-line no-underscore-dangle
      const _token = helper.parseJwt(userState);

      const obj = {
        token: userState,
        clientId: _token.data.clientId,
        company_name: _token.data.company_name,
        mainPrefix: _token.data.mainPrefix,
        prefix: _token.data.prefix,
        prefixList: _token.data.prefixList,
        companyCodeList: _token.data.companyCodeList,
        roles: _token.data.roles,
        setting: _token.data.setting,
        status: _token.data.status,
        username: _token.data.username,
        lastname: _token.data.lastname,
        firstname: _token.data.firstname,
        email: _token.data.email,
        // eslint-disable-next-line no-dupe-keys
        roles: _token.data.roles,
        adminCompanyCode: _token.data.adminCompanyCode,
      };

      store.commit('SET_USER_LOGIN', obj);
      store.commit('SET_USERDATA', _token.data);
      return true;
    }
    return false;
  }

  santralList(store) {
    const userState = this.getFromStorage('generalMeasures');
    if (userState) {
      store.commit('SET_USER_LOGIN', JSON.parse(userState));
      store.commit('SET_USERDATA', JSON.parse(userState));
      return true;
    }
    return false;
  }

  initSettingsState(store) {
    const settingsState = this.getFromStorage(`${this.prefix}${this.settings}`);
    if (settingsState) {
      store.commit('SET_SETTINGS', JSON.parse(settingsState));
      return true;
    }
    return false;
  }

  // set state to Storage
  setToStorage(key, value) {
    try {
      this.storage.setItem(key, value);
    } catch (e) {
      //   console.error(`setItem storage failed: ${e}`);
      return false;
    }
    // console.timeEnd('setToStorage');
    return true;
  }

  // get state from Storage
  getFromStorage(key) {
    // console.time('getFromStorage');
    try {
      return this.storage.getItem(key);
    } catch (e) {
      //  console.error(`getItem storage failed: ${e}`);
    }
    // console.timeEnd('getFromStorage');
    return '';
  }
}

export default function (syncStorageOption) {
  const syncStorage = new SyncStorage(syncStorageOption);
  return syncStorage.subscribe;
}
