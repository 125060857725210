<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        icon
        large
        text
        v-on="on"
      >
        <v-avatar size="36px">
          <v-icon>
            mdi-account
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list class="m-0 p-0">
        <v-list-item
          avatar
          class=" "
        >
          <v-list-item-content class=" ">
            <v-list-item-content>
              <v-list-item-title>    {{ userdata.username }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ userdata.firstname }} {{ userdata.lastname }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item
          v-if="userdata.username === 'admin@datasmarty.com'"
          avatar
          class=" "
        >
          <v-list-item-avatar>
            <v-icon
              x-large
              color="grey darken-3"
            >
              mdi-account
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="d-flex">
            <div class="col-md-3">
              <v-btn
                color="grey darken-3"
                class="white--text"
                @click="adminpage"
              >
                Admin
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item
        v-for="(item, index) in menuitems"
        :key="index"
        :to="item.href"
        :href="item.href"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <!--  <div>
        <v-btn
          id="btnModifyProfile"
          class="amber col-md-5 ml-3 mb-3 white--text"
          height="50px"
          @click="routeToProfile('modify-profile')"
        >
          <v-icon class="text-center">mdi-pen</v-icon>
          <span>Modify Profile</span>
        </v-btn>
        <v-btn
          id="btnNewUser"
          class="success col-md-5 float-right mr-3 mb-3"
          height="50px"
          @click="routeToProfile('new-user')"
        >
          <v-icon>mdi-account-plus</v-icon>
          New User
        </v-btn>
      </div> -->
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'AppProfile',
  data() {
    return {
      menuitems: [
        /*  {
          icon: 'mdi-account',
          href: '#',
          title: 'toolbar.profile',
          click: (e) => {
            console.log(e);
          },
        },
        {
          icon: 'mdi-settings',
          href: '#',
          title: 'toolbar.settings',
          click: () => {
            console.log('this.toggleSettingsPanel()');
            this.toggleSettingsPanel();
          },
        }, */
        {
          icon: 'mdi-exit-to-app',
          href: '#',
          title: 'toolbar.logout',
          click: () => {
            this.logout();
          },
        },
        {
          icon: 'mdi-open-in-app',
          href: '',
          title: 'Version : v2023.10',
          click: () => {
            // this.logout();
          },
        },
      ],
    };
  },
  computed: {

    userdata() {
      return this.$store.getters.userdata;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut');
      this.$router.push('/singin');
      window.localStorage.clear();
    },
    adminpage() {
      this.$router.push('/admin/farmlist');
    },
    toggleSettingsPanel() {
      this.$vuetify.goTo(0);
      this.$store.dispatch('SettingsPanelToggle');
    },
    routeToProfile(param) {
      this.$router.push(`/profile/${param}`);
    },
  },
};
</script>

<style scoped>
#btnModifyProfile:hover {
  color: orange !important;
  background-color: white !important;
  border: 2px solid orange;
}

#btnNewUser:hover {
  color: green !important;
  background-color: white !important;
  border: 2px solid green;
}
</style>
