<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    :height="height"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :persistent="persistent"
    :scrollable="scrollable"
    :hide-overlay="hideoverlay"
    :content-class="content_class"
  >
    <slot name="dialogContent" />
    <slot />
  </v-dialog>
</template>

<script>

export default {
  props: {
    width: {
      type: String,
      default: '500',
    },
    height: {
      type: String,
      default: '200',
    },
    maxHeight: {
      type: String,
      default: () => '',
    },
    maxWidth: {
      type: String,
      default: () => '',
    },
    persistent: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: () => false,
    },
    scrollable: {
      type: Boolean,
      default: () => false,
    },
    hideoverlay: {
      type: Boolean,
      default: () => false,
    },
    content_class: {},
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    dialog() {
      this.$emit('input', this.dialog);
    },
  },
  mounted() {
    this.dialog = this.value;
  },
  methods: {
  },

};
</script>

<style></style>
