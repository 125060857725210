<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :label="label"
    :solo="solo"
    :outlined="outlined"
    :filled="filled"
    :dense="dense"
    :autofocus="autofocus"
    :hide-details="hideDetails"
    :no-data-text="$t('no_data_available')"
    multiple
    @change="emitChange"
  >
    <template
      v-if="otherDisplay"
      v-slot:selection="{item, index}"
    >
      <template v-if="index === 0">
        <span
          v-if="item.text "

          style="max-width: 120px;"
          class="d-inline-block text-truncate"
        >
          {{ item.text }}
        </span>
        <span
          v-else
          style="max-width: 120px;"
          class="d-inline-block text-truncate"
        >
          {{ item }}
        </span>
      </template>

      <span
        v-if="index === 1"
        class="grey--text text-caption"
      >
        (+{{ selectedItem.length - 1 }}
        {{ $t('others') }})
      </span>
    </template>
    <slot />
  </v-select>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: () => (null),
    },
    solo: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    otherDisplay: {
      type: Boolean,
      default: true,
    },
    value: {},
  },
  data: () => ({
    selectedItem: '',
  }),
  watch: {
    value(value) {
      this.selectedItem = value;
    },
  },
  mounted() {
    this.selectedItem = this.value;
  },
  methods: {
    emitChange() {
      this.$emit('input', this.selectedItem);
    },
  },
};
</script>
